<template>
  <div>
    <div
      class="education-detail"
      v-if="
        typeof EducationDetail == 'object' &&
        Object.keys(EducationDetail).length
      "
    >
      <div class="content">
        <div class="education-detail__banner">
          <picture>
            <source
              :srcset="this.$root.host + EducationDetail.banner_mobile"
              media="(max-width: 767px)"
            />
            <source
              :srcset="this.$root.host + EducationDetail.banner_tablet"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="this.$root.host + EducationDetail.banner_desktop"
              media="(min-width: 1220px)"
            />
            <img
              class="education-detail__banner-image"
              :src="this.$root.host + EducationDetail.banner_desktop"
            />
          </picture>
          <h2
            class="education-detail__banner-title"
            v-html="EducationDetail.title"
          ></h2>
        </div>
      </div>
      <Breadcrumbs
        :color="'var(--breadcrumbsActive)'"
        :pages="[
          { name: 'Тренинги', link: { name: 'Education' } },
          { name: EducationDetail.title },
        ]"
      />
      <div class="content" ref="educationDetailContent">
        <div class="education-detail__row">
          <div
            class="education-detail__menu-button d-xl-none"
            @click="toggleMenu(true)"
          >
            <div class="education-detail__menu-button-icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3440_32760)">
                  <path
                    d="M4 6H20"
                    stroke="var(--asideText)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 12H14"
                    stroke="var(--asideText)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 18H18"
                    stroke="var(--asideText)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3440_32760">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="education-detail__menu-button-text">
              Содержание курса
            </div>
          </div>
          <div
            class="education-detail__aside"
            :class="{ 'education-detail__aside_opened': mobileMenu }"
          >
            <div class="education-detail__menu-button d-xl-none mb-8">
              <div class="education-detail__menu-button-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3440_32760)">
                    <path
                      d="M4 6H20"
                      stroke="var(--asideText)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 12H14"
                      stroke="var(--asideText)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 18H18"
                      stroke="var(--asideText)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3440_32760">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="education-detail__menu-button-text">
                Содержание курса
              </div>
              <div
                class="education-detail__menu-button-close"
                @click="toggleMenu(false)"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 4L20 20"
                    stroke="var(--asideText)"
                    stroke-linecap="square"
                  />
                  <path
                    d="M20 4L3 20"
                    stroke="var(--asideText)"
                    stroke-linecap="square"
                  />
                </svg>
              </div>
            </div>
            <div
              class="education-detail__module"
              :class="{ 'education-detail__module_locked': !module.is_active }"
              v-for="(module, ind) in EducationDetail.modules"
              :key="ind"
            >
              <input
                :id="`toggler_${ind}`"
                type="checkbox"
                v-if="module.is_active"
                :checked="module.id === openedModule"
              />
              <label
                :for="!module.is_active || `toggler_${ind}`"
                class="education-detail__module-title"
              >
                <div class="education-detail__module-icon">
                  <svg
                    v-if="module.is_completed"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="13"
                      cy="13"
                      r="10"
                      fill="var(--asideCheckEllipse)"
                    />
                    <path
                      d="M17 11L11.5 16L9 13.7273"
                      stroke="var(--asideCheck)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    v-else
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="13"
                      cy="13"
                      r="9"
                      stroke="var(--asideCheckEllipse)"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div class="education-detail__module-title-text">
                  <span>Модуль {{ ind + 1 }}</span>
                  {{ module.title }}
                </div>
                <div
                  class="education-detail__module-arrow"
                  v-if="module.is_active"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.2501 8.25L12.75 15.75L5.25014 8.25"
                      stroke="var(--asideText)"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
                <div class="education-detail__module-lock" v-else>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.75 8.44444V6.88889C13.75 4.74112 12.0711 3 10 3C7.92893 3 6.25 4.74111 6.25 6.88889V8.44444M10 11.9444V13.5M7.6 17H12.4C13.6601 17 14.2902 17 14.7715 16.7457C15.1948 16.522 15.539 16.165 15.7548 15.726C16 15.2269 16 14.5735 16 13.2667V12.1778C16 10.871 16 10.2176 15.7548 9.71847C15.539 9.27942 15.1948 8.92247 14.7715 8.69876C14.2902 8.44444 13.6601 8.44444 12.4 8.44444H7.6C6.33988 8.44444 5.70982 8.44444 5.22852 8.69876C4.80516 8.92247 4.46095 9.27942 4.24524 9.71847C4 10.2176 4 10.871 4 12.1778V13.2667C4 14.5735 4 15.2269 4.24524 15.726C4.46095 16.165 4.80516 16.522 5.22852 16.7457C5.70982 17 6.33988 17 7.6 17Z"
                      stroke="var(--asideText)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="education-detail__module-lock-text">
                    {{ module.available_at }}
                  </div>
                </div>
              </label>

              <div class="education-detail__module-childrens">
                <div
                  class="education-detail__module-children"
                  v-for="(child, ind2) in module.blocks"
                  :class="{
                    'education-detail__module-children_current':
                      child.slug === activeBlock.slug,
                    'education-detail__module-children_disable': blockIsDisable(
                      module,
                      ind,
                      child,
                      ind2
                    ),
                  }"
                  @click="openBlock(module.slug, child.slug)"
                  :key="ind2"
                >
                  <div class="education-detail__module-children-icon">
                    <svg
                      v-if="
                        child.is_completed && child.test_completed !== false
                      "
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="13"
                        cy="13"
                        r="10"
                        fill="var(--asideCheckEllipse)"
                      />
                      <path
                        d="M17 11L11.5 16L9 13.7273"
                        stroke="var(--asideCheck)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      v-else
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="13"
                        cy="13"
                        r="9"
                        stroke="var(--asideCheckEllipse)"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                  <div class="education-detail__module-children-title">
                    {{ child.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="education-detail__main">
            <div class="education-detail__course-info">
              <div class="education-detail__course-stat">
                <div
                  class="
                    education-detail__course-modules
                    education-detail__course-stat-item
                  "
                  v-if="
                    passedModules && EducationDetail && EducationDetail.modules
                  "
                >
                  Пройдено модулей - {{ passedModules.length }}/{{
                    onlyActiveModules.length
                  }}
                </div>
                <div
                  v-if="EducationDetail.tests_count"
                  class="
                    education-detail__course-tests
                    education-detail__course-stat-item
                  "
                >
                  Тесты - {{ EducationDetail.passed_tests }}/{{
                    EducationDetail.tests_count
                  }}
                </div>
              </div>
              <div
                class="education-detail__course-progress"
                v-if="
                  EducationDetail &&
                  EducationDetail.modules &&
                  EducationDetail.modules.length
                "
              >
                <div
                  class="education-detail__course-progress-line"
                  :class="{
                    'education-detail__course-progress-line_active':
                      item.is_completed,
                  }"
                  v-for="(item, i) in onlyActiveModules"
                  :key="i"
                ></div>
              </div>
            </div>
            <EducationTest
              @refreshTest="refreshTest"
              @nextBlockClick="
                nextBlockClick(nextBlock.module_slug, nextBlock.block_slug)
              "
              :key="testKey"
              :test="EducationBlock.test"
              :activeModule="activeModule"
              :activeBlock="activeBlock"
              :nextBlock="nextBlock"
              v-if="
                EducationBlock &&
                Object.keys(EducationBlock).length &&
                EducationBlock.test
              "
            />
            <div
              class="education-detail__content"
              :class="{ 'mt-8': EducationBlock.test }"
              v-if="
                EducationBlock &&
                Object.keys(EducationBlock).length &&
                EducationBlock.content
              "
            >
              <div class="education-detail__content-title">
                <span v-html="EducationBlock.title"></span>
                <div
                  v-if="EducationBlock.time_to_read"
                  class="education-detail__content-time"
                >
                  <svg
                    class="mr-2"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                      stroke="var(--contentTime)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {{ EducationBlock.time_to_read }}
                </div>
              </div>
              <div class="education-detail__content-body">
                <div v-html="EducationBlock.content"></div>
                <div
                  v-if="!EducationBlock.test"
                  id="education-detail__autocomplete"
                ></div>
                <div
                  v-if="nextBlock && !EducationBlock.test"
                  class="button button_white d-inline-flex mt-4 ml-auto"
                  @click="
                    nextBlockClick(nextBlock.module_slug, nextBlock.block_slug)
                  "
                >
                  Следующий блок
                  <svg
                    class="ml-2"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 4.5L16.5 12L9 19.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <PopUpDefault
              v-if="surveyOpened"
              :noClose="true"
              class="education-detail__survey"
            >
              <EducationSurvey
                @close="closeSurvey"
                :survey="EducationBlock.survey"
                :activeModule="activeModule"
                :activeBlock="activeBlock"
                :nextBlock="nextBlock"
              />
            </PopUpDefault>
          </div>
        </div>
      </div>
      <LibrarySlider
        v-if="
          EducationDetail.library &&
          EducationDetail.library.length &&
          sliderItems.length
        "
        :items="sliderItems"
        :color="'#2186AF'"
        :title="'Библиотека'"
        @clickMaterials="clickMaterials"
      />
      <div class="content">
        <div class="approval-number">
          {{
            activeBlock.approval_date ||
            "Номер одобрения: XXXXXX Дата одобрения: XXXXXX Дата истечения: XXXXXX"
          }}
        </div>
      </div>
    </div>
    <div v-if="EducationDetail === 404"><Choch class="inPage" /></div>
    <div v-if="EducationDetail === false"><Error class="inPage" /></div>
    <div
      class="training-popup"
      :class="[inputClass]"
      v-if="EducationDetail == 403"
    >
      <div class="training-popup__title">
        Тренинг доступен только для пользователей имеющих специальный код
        доступа
      </div>
      <div class="training-popup__description">
        Для получения доступа введите код в окно ниже
      </div>
      <div class="training-popup__label">Код для доступа к тренингу</div>
      <div class="training-popup__input-container">
        <div class="training-popup__input-wrap">
          <input type="text" v-model="code" class="training-popup__input" />
          <div class="training-popup__success-icon">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 4L7 11L3.5 7"
                stroke="#C4D600"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>

        <div class="training-popup__error-text">Неправильный код</div>
      </div>
      <div
        class="
          modal-popup__btn-accept
          d-md-inline-flex
          button button_pink
          ml-auto
          mr-auto
          mt-4
          px-8
        "
        @click="sendCode()"
      >
        Отправить
      </div>
    </div>
    <div v-html="CSSVariables"></div>
    <div v-if="EducationDetail === 'loading'" class="education-preloader">
      <Preloader class="" />
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import { bus } from "@/main";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Choch from "@/views/404.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import EducationTest from "@/components/pageComponents/education/EducationTest.vue";
import EducationSurvey from "@/components/pageComponents/education/EducationSurvey.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import Error from "@/views/Error.vue";
import Preloader from "@/components/Preloader.vue";
import SimpleCarousel from "@/components/pageComponents/education/SimpleCarousel.vue";
import Vue from "vue";
export default {
  metaInfo() {
    if (this.EducationDetail.name) {
      return { title: this.EducationDetail.name.replace(/<[^>]*>?/gm, "") };
    } else {
      return { title: this.EducationDetail.name };
    }
  },
  name: "EducationDetail",
  components: {
    Breadcrumbs,
    Choch,
    Error,
    LibrarySlider,
    EducationTest,
    EducationSurvey,
    PopUpDefault,
    Preloader,
  },
  data: () => ({
    testKey: 0,
    openedModule: null,
    code: null,
    inputClass: "",
    mobileMenu: false,
    surveyOpened: false,
    settingsSlider: {
      slidesToShow: 4,
      draggable: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            infinite: false,
            variableWidth: true,
          },
        },
      ],
    },
  }),
  computed: {
    ...mapGetters(["EducationDetail", "EducationBlock"]),
    sliderItems() {
      return this.EducationDetail.library.length
        ? this.EducationDetail.library.map(function (el) {
            return {
              ...el,
              page: el.video
                ? "VideoDetail"
                : el.episodes
                ? "PodcastDetail"
                : "TextDetail",
            };
          })
        : null;
    },
    CSSVariables() {
      if (this.EducationDetail.colors) {
        return `<style>${this.EducationDetail.colors}</style>`;
      }
      return `
      <style>
       :root {
          --back: linear-gradient( 180.32deg, #012511 4.25%, #0ea07d 65.35%, #26c9ca 99.73%);
          --breadcrumbsActive: #fff;
          --aside: #043627;
          --asideText: #fff;
          --asideTextActive: #fff;
          --asideActive: #0b5c45;
          --asideHighlight: #6bc399;
          --asideCheck: #fff;
          --asideItemBorder: rgba(107, 195, 153, 0.4);
          --asideCheckEllipse: #6BC399;
          --contentHeader: rgba(0, 37, 28, 0.502);
          --contentTitle: #fff;
          --contentBody: rgba(17, 17, 17, 0.2);
          --contentText: #fff;
          --contentTime: #2d7456;
          --testCounter: #6bc399;
          --testRadio: #6bc399;
          --testRadioBack: #fff;
          --button: #fff;
          --buttonText: #1f1f1f;
          --buttonHover: #043627;
          --buttonHoverText: #fff;
          --progressBack: #043627;
          --progressText: #fff;
          --progressLine: rgba(107, 195, 153, .4);
          --progressActiveLine: #fff;
          --buttonEmpty: #fff;
          --buttonEmptyText: #fff;
          --buttonEmptyHover: #6bc399;
          --buttonEmptyHoverText: #fff;
          --tableHead: #6bc399;
          --tableBody: #fff;
          --dropdownBorder: #fff;
        }
      </style>
      `;
    },
    activeModule() {
      if (!this.EducationDetail) return null;
      if (!this.EducationBlock) return null;
      if (
        this.EducationDetail &&
        (!this.EducationDetail.modules || !this.EducationDetail.modules.length)
      )
        return null;
      return (
        this.EducationDetail.modules.find((el) =>
          el.blocks.find((course) => course.slug === this.EducationBlock.slug)
        ) || this.EducationDetail.modules[0]
      );
    },
    activeBlock() {
      return Object.keys(this.EducationBlock).length
        ? {
            ...this.activeModule.blocks.find(
              (course) => course.slug === this.EducationBlock.slug
            ),
            ...this.EducationBlock,
          }
        : { ...this.activeModule.blocks[0], ...this.EducationBlock };
    },
    activeBlockIndex() {
      if (
        this.activeModule &&
        this.activeModule.blocks &&
        this.activeModule.blocks.length
      ) {
        return Object.keys(this.EducationBlock).length
          ? this.activeModule.blocks.findIndex(
              (course) => course.slug === this.activeBlock.slug
            )
          : 0;
      } else {
        return 0;
      }
    },
    activeModuleIndex() {
      if (!this.EducationDetail) return null;
      if (!this.EducationBlock) return null;
      if (
        this.EducationDetail &&
        (!this.EducationDetail.modules || !this.EducationDetail.modules.length)
      )
        return null;
      let index = this.EducationDetail.modules.findIndex((el) =>
        el.blocks.find((course) => course.slug === this.EducationBlock.slug)
      );
      return index >= 0 ? index : 0;
    },
    nextBlock() {
      if (this.activeBlockIndex == this.activeModule.blocks.length - 1) {
        if (this.activeModuleIndex == this.EducationDetail.modules.length - 1) {
          return false;
        } else if (
          !this.EducationDetail.modules[this.activeModuleIndex + 1].is_active
        ) {
          return false;
        } else {
          return {
            module_slug:
              this.EducationDetail.modules[this.activeModuleIndex + 1].slug,
            block_slug:
              this.EducationDetail.modules[this.activeModuleIndex + 1].blocks[0]
                .slug,
          };
        }
      }
      return {
        module_slug: this.EducationDetail.modules[this.activeModuleIndex].slug,
        block_slug:
          this.EducationDetail.modules[this.activeModuleIndex].blocks[
            this.activeBlockIndex + 1
          ].slug,
      };
    },
    passedModules() {
      if (!this.EducationDetail) return [];
      if (
        this.EducationDetail &&
        (!this.EducationDetail.modules || !this.EducationDetail.modules.length)
      )
        return [];
      return this.EducationDetail.modules.filter((el) => el.is_completed);
    },
    onlyActiveModules() {
      if (!this.EducationDetail) return [];
      if (
        this.EducationDetail &&
        (!this.EducationDetail.modules || !this.EducationDetail.modules.length)
      )
        return [];
      return this.EducationDetail.modules.filter((module) => module.is_active);
    },
  },
  methods: {
    ...mapActions([
      "fetchEducationDetail",
      "fetchEducationBlock",
      "fetchEducationModules",
    ]),
    ...mapMutations(["checkedEducationBlock"]),
    refreshTest() {
      this.testKey++;
    },
    toggleMenu(state) {
      this.mobileMenu = state;
      bus.$emit("scrollLock", state);
    },
    openPopup(state) {
      bus.$emit("scrollLock", state);
    },
    async sendCode() {
      this.$axios({
        method: "POST",
        url: `/api/education/trenings/${this.$route.params.slug}/code/`,
        data: { code: this.code },
      })
        .then(async (response) => {
          this.inputClass = "training-popup_success";
          await this.fetchEducationDetail({
            slug: this.$route.params.slug,
          }).then(() => {
            this.openBlock(
              this.onlyActiveModules[0].slug,
              this.onlyActiveModules[0].blocks[0].slug
            );
          });
        })
        .catch((error) => {
          console.log(error);
          this.inputClass = "training-popup_error";
        });
    },
    blockIsDisable(module, moduleIndex, block, blockIndex) {
      if (moduleIndex === 0) {
        if (blockIndex === 0) {
          return false;
        } else {
          if (module.blocks[blockIndex - 1].is_completed) {
            return false;
          }
        }
      } else {
        if (blockIndex === 0) {
          if (
            this.EducationDetail.modules[moduleIndex - 1].blocks.length &&
            this.EducationDetail.modules[moduleIndex - 1].blocks.slice(-1)[0]
              .is_completed
          ) {
            return false;
          }
        } else {
          if (module.blocks[blockIndex - 1].is_completed) {
            return false;
          }
        }
      }
      return true;
    },
    openBlock(module_slug, block_slug) {
      this.fetchEducationBlock({
        module_slug: module_slug,
        block_slug: block_slug,
        training_slug: this.$route.params.slug,
      }).then(() => {
        // if (
        //   this.activeModule &&
        //   this.activeModule.blocks &&
        //   this.activeModule.blocks.length
        // ) {
        //   if (
        //     this.activeBlockIndex === this.activeModule.blocks.length - 1 &&
        //     this.activeModule.slug === this.onlyActiveModules.slice(-1)[0].slug
        //   ) {
        //     if (!this.activeBlock.test && !this.activeBlock.is_completed) {
        //       // this.$axios({
        //       //   method: "POST",
        //       //   url: `/api/education/modules/${this.activeModule.slug}/blocks/${this.activeBlock.slug}/completed/`,
        //       // }).then(() => {
        //       //   this.fetchEducationModules({ slug: this.$route.params.slug });
        //       // });
        //     }
        //   }
        // }
        this.toggleMenu(false);
        this.$refs.educationDetailContent.scrollIntoView({
          behavior: "instant",
        });
        this.observer();
        this.$nextTick(() => {
          document
            .querySelectorAll(".education-detail__content-body img")
            .forEach((img) => {
              const fullScreen = `<div class="full-screen mb-6">
                                  <div data-coolbox="${img.src}" class="full-screen__trigger d-xl-none"><svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <rect width="40" height="40" rx="8" fill="#830051"></rect>
                                      <path d="M23 11H29V17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                      <path d="M17 29H11V23" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                      <path d="M29 11L22 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                      <path d="M11 29L18 22" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                  </div>
                                  ${img.outerHTML}
                                </div>`;
              img.insertAdjacentHTML("afterend", fullScreen);
              img.remove();
            });

            document
          .querySelectorAll(".simple-carousel__container:not(.loaded)")
          .forEach((slider) => {
            let ComponentClass = Vue.extend(SimpleCarousel);
            let instance = new ComponentClass({
              propsData: {
                Slides: Array.from(slider.children),
                color: "#830051",
                autoplay: slider.getAttribute("data-autoplay"),
                adaptiveHeight: slider.getAttribute("data-adaptiveHeight"),
              },
            });
            instance.$on("openImage", (e) => vm.$emit("openImage", e));
            instance.$mount(); // pass nothing
            slider.innerHTML = "";
            slider.appendChild(instance.$el);
            slider.classList.add("loaded");
          });
        });
      });
    },
    nextBlockClick(next_module_slug, next_block_slug) {
      this.openBlock(next_module_slug, next_block_slug);
    },
    closeSurvey() {
      this.$set(this, "surveyOpened", false);
      bus.$emit("scrollLock", false);
      this.$axios({
        method: "POST",
        url: `/api/education/modules/${this.activeModule.slug}/blocks/${this.activeBlock.slug}/completed/`,
      }).then(() => {
        if (typeof ym !== "undefined") {
          // console.log(this.EducationDetail.title.replace(/<[^>]*>?/gm, ""))
          // console.log(this.activeModule.title.replace(/<[^>]*>?/gm, ""))
          // console.log(this.activeBlock.title.replace(/<[^>]*>?/gm, ""))
        }
        this.fetchEducationModules({
          slug: this.$route.params.slug,
        });
        this.openBlock(this.activeModule.slug, this.activeBlock.slug);
      });
    },
    observer() {
      const vm = this;
      const createObserver = () => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                if (
                  this.EducationBlock.survey &&
                  Object.keys(this.EducationBlock.survey).length &&
                  !this.EducationBlock.survey.is_completed
                ) {
                  this.$set(this, "surveyOpened", true);
                  bus.$emit("scrollLock", true);
                } else {
                  if (!this.activeBlock.is_completed) {
                    this.$axios({
                      method: "POST",
                      url: `/api/education/modules/${this.activeModule.slug}/blocks/${this.activeBlock.slug}/completed/`,
                    }).then(() => {
                      if (typeof ym !== "undefined") {
                        // console.log(this.EducationDetail.title.replace(/<[^>]*>?/gm, ""))
                        // console.log(this.activeModule.title.replace(/<[^>]*>?/gm, ""))
                        // console.log(this.activeBlock.title.replace(/<[^>]*>?/gm, ""))
                      }
                      this.fetchEducationModules({
                        slug: this.$route.params.slug,
                      });
                      this.checkedEducationBlock(true);
                      // this.openBlock(this.activeModule.slug, this.activeBlock.slug)
                    });
                  }
                }
              }
            });
          },
          {
            // процент пересечения целевого элемента с областью просмотра
            // 10%
            threshold: 0.3,
          }
        );

        // находим все секции
        const autocomplete = document.querySelector(
          "#education-detail__autocomplete"
        );
        // начинаем за ними наблюдать
        if (autocomplete) {
          observer.observe(autocomplete);
        }
      };

      createObserver();
    },
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        // ym(91468266, "reachGoal", "forsiga", {
        //   forsiga: {
        //     "material click": {
        //       [name]: {
        //         ...this.$root.ymFields,
        //       },
        //     },
        //   },
        // });
      }
    },
  },
  async mounted() {},
  watch: {
    "$route.params.slug": {
      handler: async function () {
        if (!this.$route.query.access) {
          await this.fetchEducationDetail({
            slug: this.$route.params.slug,
          }).then(() => {
            // Получение последнего пройденного блока с фронта start
            // let last_module = this.onlyActiveModules.find(
            //   (module) => !module.is_completed
            // ) || this.onlyActiveModules.slice(-1)[0];
            //  let last_block = last_module.blocks.find(
            //   (block) => !block.is_completed
            // ) || this.onlyActiveModules.slice(-1)[0].blocks.slice(-1)[0];
            // this.openBlock(last_module.slug, last_block.slug);
            // Получение последнего пройденного блока с фронта end
            // Получение последнего пройденного блока с бека start
            if (this.EducationDetail.last_block) {
              let module = this.EducationDetail.modules.find((module) =>
                module.blocks.find(
                  (block) => block.slug === this.EducationDetail.last_block.slug
                )
              );
              let module_slug = module.slug;
              this.openBlock(module_slug, this.EducationDetail.last_block.slug);
              this.openedModule = module.id;
            } else if (
              typeof this.EducationDetail == "object" &&
              Object.keys(this.EducationDetail).length
            ) {
              this.openBlock(
                this.onlyActiveModules[0].slug,
                this.onlyActiveModules[0].blocks[0].slug
              );
            }
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.education-detail {
  padding-top: 1px;
  padding-bottom: 24px;
  background: var(--back);

  &__banner {
    margin-top: 32px;
    padding-top: 1px;
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1219px) {
      margin-top: 0;
      height: 300px;
      width: auto;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
      margin-left: -16px;
      margin-right: -16px;
      height: 300px;
      width: auto;
    }

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &-title {
      position: relative;
      z-index: 2;
      margin-top: 48px;
      margin-left: 48px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 42px;
      font-weight: 400;
      line-height: 48px;
      color: #fff;

      @media screen and (max-width: 1219px) {
        margin-top: auto;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        margin-top: auto;
        font-size: 32px;
        line-height: 38px;
        margin-left: 16px;
      }
    }
  }

  &__row {
    margin-top: 64px;
    display: grid;
    grid-template-columns: 294px minmax(800px, auto);
    gap: 32px;
    align-items: flex-start;

    @media screen and (max-width: 1219px) {
      grid-template-columns: 100%;
    }
  }

  &__aside {
    position: sticky;
    top: 15px;
    z-index: 10;
    background-color: var(--aside);
    padding: 16px 8px;
    border-radius: 20px;

    @media screen and (max-width: 1219px) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding: 32px 76px;
      z-index: 10000000000;
      border-radius: 0;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
      overflow-y: auto;

      & .education-detail {
        &__menu-button {
          position: sticky;
          z-index: 10;
          top: -15px;
        }
      }
    }

    &_opened {
      display: block;
    }
  }

  &__menu-button {
    padding: 16px 8px;
    border-radius: 8px;
    background-color: var(--aside);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1219px) {
      position: sticky;
      z-index: 10;
      top: 15px;
    }

    &-text {
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: var(--contentText);
    }

    &-icon {
      margin-right: 8px;
    }

    &-close {
      margin-left: auto;
    }
  }

  &__module {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--asideItemBorder);

    &:hover {
      & .education-detail {
        &__module {
          &-lock {
            &-text {
              visibility: visible;
              opacity: 1;
              transition: opacity 0.2s linear;
            }
          }
        }
      }
    }

    &_locked {
      & .education-detail {
        &__module {
          &-title-text {
            opacity: 0.5;
          }

          &-icon {
            opacity: 0.5;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    & input {
      display: none;

      &:checked {
        & ~ .education-detail {
          &__module {
            &-childrens {
              max-height: 10000px;
              margin-top: 32px;
            }

            &-title {
              & .education-detail {
                &__module {
                  &-arrow {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }
      }
    }

    &-arrow {
      transform: rotate(0);
      transition: 0.3s;
      flex-shrink: 0;
      margin-left: 8px;

      @media screen and (max-width: 1219px) {
        margin-left: auto;
      }
    }

    &-lock {
      position: relative;
      flex-shrink: 0;
      margin-left: 8px;

      &-text {
        position: absolute;
        top: calc(100% + 15px);
        left: 50%;
        transform: translate(-50%);
        border-radius: 12px;
        width: max-content;
        max-width: 250px;
        padding: 8px 16px;
        content: attr(data-text) "";
        display: block;
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(5px);
        z-index: 2;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--contentText);

        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.2s, opacity 0.2s linear;

        &::before {
          position: absolute;
          bottom: calc(100% - 4px);
          left: 50%;
          transform: translate(-50%);
          border-radius: 12px;
          width: 32px;
          height: 16px;
          background-image: url("data:image/svg+xml,%3Csvg width='32' height='12' viewBox='0 0 32 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_b_3333_3052)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 0C20 0 24 12 32 12H0C8 12 12 0 16 0Z' fill='white' fill-opacity='0.5'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_b_3333_3052' x='-26.4' y='-26.4' width='84.8' height='64.8' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeGaussianBlur in='BackgroundImageFix' stdDeviation='13.2'/%3E%3CfeComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_3333_3052'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_3333_3052' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          content: "";
          display: block;
        }
      }
    }

    &-childrens {
      max-height: 0;
      margin-top: 0;
      overflow: hidden;
      // transition: 0.3s;
    }

    &-children {
      padding: 8px;
      margin-bottom: 8px;
      transition: 0.3s;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 8px;
      cursor: pointer;

      &_disable {
        opacity: 0.4;
        pointer-events: none;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        margin: auto 0;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--asideText);
      }

      &-icon {
        margin-right: 8px;
        flex-shrink: 0;
      }

      &:hover {
        background-color: var(--asideActive);
        & > .education-detail__module-children-title {
          color: var(--asideTextActive);
        }
      }

      &_current {
        background-color: var(--asideActive);

        & > .education-detail__module-children-title {
          color: var(--asideTextActive);
        }
      }
    }

    &-title {
      cursor: pointer;
      padding-right: 8px;
      padding-left: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &-text {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: var(--asideText);

        @media screen and (max-width: 1219px) {
          margin-right: 8px;
        }

        & span {
          color: var(--asideHighlight);
        }
      }
    }

    &-icon {
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  &__course-info {
    padding: 16px;
    position: sticky;
    z-index: 9;
    top: 15px;
    background-color: var(--progressBack);
    border-radius: 8px;
    margin-bottom: 16px;

    @media screen and (max-width: 1219px) {
      position: static;
    }
  }

  &__course-stat {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;

    &-item {
      font-family: "Roboto Slab", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--progressText);
    }
  }

  &__course-progress {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    gap: 2px;

    &-line {
      background-color: var(--progressLine);
      height: 6px;
      border-radius: 3px;

      &_active {
        background-color: var(--progressActiveLine);
      }
    }
  }

  &__course-modules {
    margin-right: auto;
  }

  &__course-tests {
    margin-left: 30px;
  }

  &__content {
    &-title {
      padding: 24px;
      background: var(--contentHeader);
      border-radius: 20px 20px 0 0;
      font-family: "Roboto", sans-serif;
      font-size: 23px;
      font-weight: 500;
      line-height: 29px;
      color: var(--contentTitle);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &-time {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: auto;
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: var(--contentTime);
      flex-shrink: 0;
    }

    &-body {
      border-radius: 0 0 20px 20px;
      padding: 24px;
      background: var(--contentBody);
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 767px) {
        // max-width: calc(100vw - 15px);
      }
    }
  }
}

.library-slider {
  // padding-bottom: 64px;
}

.button_white {
  color: var(--buttonText);
  background-color: var(--button);

  &:hover {
    color: var(--buttonHoverText);
    background-color: var(--buttonHover);
  }
}

.training-popup {
  display: flex;
  flex-direction: column;
  padding: 64px 0;
  @media screen and (max-width: 767px) {
    padding: 32px 0;
  }
  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: #830051;
    margin-bottom: 24px;
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #3c4242;
    margin-bottom: 24px;
  }

  &__label {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #1f1f1f;
  }

  &__input-container {
    position: relative;
    max-width: 280px;
    margin: 0 auto;
  }

  &__input {
    padding: 0 32px 0 16px;
    width: 100%;
    height: 100%;
    max-width: 384px;
    background-color: #f8f8f8;
    border: 1px solid #d2d2d2;
    border-radius: 99px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #b2b4b4;
      opacity: 1; /* Firefox */
    }

    &:focus {
      border-color: #d0006f;
    }

    &-wrap {
      position: relative;
      width: 100%;
      height: 40px;
    }
  }

  &__error-text {
    display: none;
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #cb4050;
  }

  &__success-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &_error {
    & .training-popup__input {
      border-color: #cb4050;
      background-color: #faebed;
      color: #cb4050;
    }

    & .training-popup__error-text {
      display: block;
      color: #cb4050 !important;
    }
  }

  &_success {
    & .training-popup__input {
      border-color: #c4d600;
      background-color: #fafce9;
    }

    & .training-popup__success-icon {
      display: block;
    }
  }
}

.approval-number {
  margin-top: 48px;
  margin-bottom: 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--contentText);
}
</style>

<style lang="scss">
.education-detail__content-body {
  img {
    margin: 0 auto 16px;
    max-width: 100%;
    width: 100%;
    border-radius: 10px;

    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }

  video {
    margin: 0 auto 16px;
    max-width: 100%;
    width: 100%;
    border-radius: 10px 10px 0 0;

    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }

  p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: var(--contentText);
    margin-bottom: 8px;
  }

  b {
    font-weight: 800;
  }

  ul {
    list-style-type: none;
    color: var(--contentText);

    & li {
      margin-bottom: 8px;
      position: relative;
      padding-left: 15px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      display: block;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        position: absolute;
        left: 0;
        top: 8px;
        width: 4px;
        height: 4px;
        background-color: currentColor;
        color: inherit;
        border-radius: 50%;
        display: block;
        content: "";
      }
    }
  }

  ol {
    list-style-type: decimal;
    list-style: decimal;
    color: var(--contentText);
    padding-left: 25px;

    & li {
      margin-bottom: 8px;
      position: relative;

      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  sup {
    vertical-align: super;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 0;
    // color: #830051;
    color: inherit;
    white-space: nowrap;
  }

  .img-descr {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #babdbd;
  }

  .title-1 {
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: var(--contentText);
  }

  .title-2 {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: var(--contentText);
  }

  .small {
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;

    & * {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }

    & a {
      line-break: anywhere;
      white-space: pre-wrap;
      text-decoration: underline;
    }
  }

  .overflow {
    padding-bottom: 15px;
    overflow: auto;
    // -ms-overflow-style: scrollbar;
    // scrollbar-color: var(--tableHead) transparent;
    // scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--tableHead);
      border-radius: 5px;
      height: 8px;
    }

    &::-webkit-scrollbar--track-piece {
      border-radius: 5px;
      background-color: #ebefee;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #ebefee;
      border: 3px solid #fff;
    }

    &::-moz-scrollbar-button:decrement,
    &::-moz-scrollbar-button:increment,
    &::-moz-scrollbar-button:vertical:start:decrement,
    &::-moz-scrollbar-button:vertical:end:increment,
    &::-moz-scrollbar-button:horizontal:start:decrement,
    &::-moz-scrollbar-button:horizontal:end:increment,
    &::-webkit-scrollbar-button:vertical:start:decrement,
    &::-webkit-scrollbar-button:vertical:end:increment,
    &::-webkit-scrollbar-button:horizontal:start:decrement,
    &::-webkit-scrollbar-button:horizontal:end:increment {
      width: 0px !important;
      height: 0px !important;
    }
  }

  .overflow {
    & table {
      & tbody {
        & td {
          hyphens: manual;
        }
      }
    }
  }

  .full-screen {
    position: relative;

    &__trigger {
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 16px;
      right: 16px;
      z-index: 10;
      display: block;
      cursor: pointer;
    }
  }

  table {
    width: 100%;
    & thead {
      & tr {
        background-color: var(--tableBody);
      }
      & td {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: var(--tableHead);
        text-align: center;
        padding: 8px;
        border-color: var(--tableHead);
      }
    }
    & tbody {
      & td {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: var(--contentText);
        text-align: left;
        padding: 8px;
        hyphens: auto;
      }
    }
    & td {
      border: 1px solid var(--tableBody);
    }
  }

  .dropdown {
    padding-bottom: 0;
    transition: linear 0.1s;
    border-bottom: 1px solid var(--dropdownBorder);
    @media screen and (max-width: 767px) {
      transition: none;
    }
    &__inner {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: linear 0.1s;
    }
    &__head {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      height: auto;
      margin-top: 32px;
      padding-bottom: 24px;
      cursor: pointer;
      &-title {
        width: 95%;
        display: flex;
        align-items: center;
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;
        @media screen and (max-width: 767px) {
          font-size: 21px;
          line-height: 26px;
        }
        span {
          margin-left: 8px;
          padding: 4px 8px;
          border-radius: 99px;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 13px;
          @media screen and (max-width: 767px) {
            margin-left: 0;
            margin-bottom: 8px;
          }
        }
      }
      &-arrow {
        width: 18px;
        flex-shrink: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: linear 0.3s;
        cursor: pointer;
      }
    }
    & input {
      display: none;
      &:checked {
        padding-bottom: 16px;
        & ~ .dropdown {
          &__inner {
            padding-bottom: 16px;
            max-height: 100%;
            opacity: 1;
            overflow: visible;
            @media screen and (max-width: 767px) {
              overflow: auto;
              max-height: calc(100% - 100px);
            }
          }

          &__head {
            & .dropdown__head-arrow {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    video {
      width: 100%;
      height: auto;
    }
  }
}

.education-detail {
  &__survey {
    & .modal-popup {
      &__content {
        background: var(--back);
        border-radius: 20px;
        padding: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.education-preloader {
  padding: 80px 0;
  width: 100%;
  height: calc(100% - 48px - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;

  & .lds-ring {
    width: 100px;
    height: 100px;

    & div {
      border-width: 6px;
      border-color: #830051 transparent transparent transparent;
    }
  }
}
</style>